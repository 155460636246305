import { Alpine } from '@shared/livewire.js'

Alpine.data('loadStripePaymentForm', (props) => ({
    props: props,
    stripe: null,
    elements: null,
    error: null,
    submitting: false,
    showButton() {
        return !this.submitting
    },
    init() {
        this.stripe = Stripe(this.props.publicKey)

        this.elements = this.stripe.elements({
            clientSecret: this.props.clientSecret,
        })
        const cardElement = this.elements.create('payment')
        cardElement.mount('#payment-element')
    },
    async submit() {
        this.submitting = true
        let submitError = null
        if (this.props.useSetupIntent) {
            const { error } = await this.stripe.confirmSetup({
                elements: this.elements,
                confirmParams: {
                    return_url: this.props.redirect,
                },
            })
            submitError = error
        } else {
            const { error } = await this.stripe.confirmPayment({
                elements: this.elements,
                confirmParams: {
                    return_url: this.props.redirect,
                },
            })
            submitError = error
        }

        if (submitError) {
            this.error = submitError.message
            this.submitting = false
        }
    },
}))
